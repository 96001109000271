@font-face {
  font-family: 'Brand Font';
  src: url('brand-font.ttf');
}

@keyframes heartbeat {
  0% {
    transform: scale3d(1, 1, 1);
  }
  12% {
    transform: scale3d(.95, .95, 1);
  }
  24% {
    transform: scale3d(1, 1, 1);
  }
  36% {
    transform: scale3d(.95, .95, 1);
  }
  48% {
    transform: scale3d(1, 1, 1);
  }
  100% {
    transform: scale3d(1, 1, 1);
  }
}

.App {
  text-align: center;
  font-family: 'Montserrat', sans-serif;
}

.App-logo {
  height: 210px;
  pointer-events: none;
  margin: 30px;
  animation: heartbeat 1s 2;
  transform: scale3d(1, 1, 1);
}

.App-header {
  background-color: #fff;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: #000;
}

.App-link {
  color: #61dafb;
}


.Logo-brand {
  color: rgb(200,200,200);
  font-family: "Brand Font";
  font-size: 76px;
  padding: 0px;
  margin: 0px;
  line-height: 60px;
}

.Logo-brand span {
  color: #000;
  padding: 0px;
  margin: 0px;
}

.Logo-slogan {
  font-family: 'Montserrat', sans-serif;
  font-size: 14px;
  text-transform: uppercase;
  letter-spacing: 6px;
  margin-bottom: 30px;;
}

.Content {
  color: rgb(200,200,200);
}

